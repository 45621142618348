import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigurationService } from './configuration.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ConveniosService {

  constructor(
    private http: HttpClient,
    private config: ConfigurationService,
    private userSrv: UserService
  ) { }

  getConvenios(cod_geren: number, cod_obra: number): Observable<any>{
    return this.http.get<any>(
      this.config.getBaseUrl() + '/invoices/convenios/' + cod_geren.toString() + '/' + cod_obra.toString(),
      { headers: this.config.getHeaders() }
    );
  }
}
