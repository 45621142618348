import { Router } from '@angular/router';
import { ValidaResult } from './../interfaces/valida-result';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private user: ValidaResult;

  constructor(
    private router: Router
  ) { }

  setUser(user: ValidaResult): void {
    this.user = user;
    localStorage.setItem('user', JSON.stringify(user));
  }

  getUser(): ValidaResult {
    if   (this.user){
      return this.user;
    }  else{
      return JSON.parse(localStorage.getItem('user'));
    }
  }

  isLogin(){
    if( this.router.url !== '/' &&  this.router.url !== '/change'){
      if ( this.getUser() === null ){
        this.router.navigate(['/'], {  });
      }
    }
  }

  isLoginTrue(): boolean{
    var user = this.getUser()
    return user != undefined; 
  }
}
