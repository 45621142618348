import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from './configuration.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TipoDocumentosService {

  constructor(
    private http: HttpClient,
    private config: ConfigurationService
  ) { }

  getTiposDeDocumentos(): Observable<any[]>{
    return this.http.get<any[]>(
      this.config.getBaseUrl() + '/invoices/documentos',
      { headers: this.config.getHeaders() }
    );
  }

  getFiles(matricula: number, tipo: number, yyyymm: string): Observable<any[]>{
    return this.http.get<any[]>(
      this.config.getBaseUrl() + '/invoices/downloads/'+ matricula.toString() + '/' + tipo.toString() + '/' + yyyymm,
      { headers: this.config.getHeaders() }
    );
  }

  getPdf(url: any): Observable<any>{
    return this.http.post<any>(
      this.config.getBaseUrl() + '/invoices/pdf/',
      url,
      {
        headers: this.config.getHeaders(),
        responseType: 'blob' as 'json'
      }
    );
  }
}
