import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from './configuration.service';
import { UserService } from './user.service';
import { Prestador } from '../interfaces/prestador';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrestadorService {

  constructor(
    private http: HttpClient,
    private config: ConfigurationService,
    private userSrv: UserService
  ) { }

  getPrestadores(matricula: number): Observable<Prestador[]>{
    return this.http.get<Prestador[]>(
      this.config.getBaseUrl() + '/invoices/prestador/all/' + matricula.toString(),
      { headers: this.config.getHeaders() }
    );
  }
}
