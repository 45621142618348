import { Component, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from './../services/user.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { InvoicesService } from '../services/invoices.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  @ViewChild('modal', null) modal: NgbModal;
  fileURL:string;
  pdfUrl: SafeResourceUrl;
  isFile:boolean = false;

  constructor(
    private modalSrv: NgbModal,
    private userSrv: UserService,
    private invoiceSrv: InvoicesService    
  ) { }

  ngOnInit(){   
    //this.getPathPdf();        
  }

  getPathPdfB() {
    this.invoiceSrv.getPathPdf().subscribe(result => { 
      const fileName = result.path; // Nombre del archivo
      const fileUrl = `../assets/${fileName}`;
  
      // Verificar si el archivo existe
      this.checkFileExists(fileUrl).then(exists => {
        if (exists) {
          // Generar un timestamp único
          const timestamp = new Date().getTime();
          // Agregar el timestamp a la URL para evitar la caché
          const urlWithTimestamp = `${fileUrl}?timestamp=${timestamp}`;
  
          this.fileURL = urlWithTimestamp;
          if (this.isTipoMG()) {
            this.open();
          }
        } else {
          // El archivo no existe, realizar alguna acción en consecuencia
          console.log('El archivo no existe');
        }
      });
    });
  }

  getPathPdf() {
    this.invoiceSrv.getPathPdf().subscribe(result => { 
      if(result.path){
        const fileName = result.path; // Nombre del archivo
        const fileUrl = `../assets/${fileName}`;
    
        // Verificar si el archivo existe
        this.checkFileExists(fileUrl).then(exists => {
          if (exists){
            // Generar un timestamp único
            const timestamp = new Date().getTime();
            // Agregar el timestamp a la URL para evitar la caché
            const urlWithTimestamp = `${fileUrl}?timestamp=${timestamp}`;
    
            this.fileURL = urlWithTimestamp;
            if (this.isTipoMG()) {
              this.isFile = true;
              this.open();
            }
          } else {
            console.log('El archivo no existe');
            console.log('isFile: ', this.isFile);
          }
        });
      }else{
        console.log('El archivo no existe');
        console.log('isFile: ', this.isFile);
      }
      
    },error => {       
      console.log('error en el servicio para obtener el path del pdf');
      console.log('isFile: ', this.isFile);
    });
  }
  
  checkFileExists(url: string): Promise<boolean> {
    return fetch(url)
      .then(response => {
        return response.ok;
      })
      .catch(() => false);
  }    

  openModal(content) {
    let options: NgbModalOptions = {
      size: 'xl',
      centered: true,
      backdrop : 'static',
      keyboard : false
    }

    this.modalSrv.open(content, options).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(reason);
    });
  }

  open(){
    this.openModal(this.modal); 
  }

  isTipoMG(): boolean {    
    const user = this.userSrv.getUser();
    if(user.tipo !== null){
      return user.tipo.toLowerCase() === 'm' || user.tipo.toLowerCase() === 'g';
    }else{
      return false;
    }
  }
  
}
