import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private baseUrl = 'https://ara-rosario.com.ar/api';
  //private baseUrl = 'https://localhost:44355';
  
  constructor() {

  }

  public getBaseUrl(): string{
    return this.baseUrl;
  }

  /*
  public getHeaders(): HttpHeaders {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    console.log(headers);
    return headers;
  }
  */

  public getHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Accept', 'application/json');
    headers = headers.set('Cache-Control', 'no-cache, no-store, must-revalidate');
    headers = headers.set('Pragma', 'no-cache');
    headers = headers.set('Expires', '0');
    console.log(headers);
    return headers;
  }
  
}
