import { Component, OnInit } from '@angular/core';
import { ObraSocial } from '../interfaces/obra-social';
import { ObraSocialService } from '../services/obra-social.service';
import { UserService } from '../services/user.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ConveniosService } from '../services/convenios.service';

@Component({
  selector: 'app-convenios',
  templateUrl: './convenios.component.html',
  styleUrls: ['./convenios.component.css']
})
export class ConveniosComponent implements OnInit {

  obras_sociales: any[];
  selectedOS = null;
  convenios: any[];
  fecha: Date;
  message: string = null;
  codigoOS: number;
  
  constructor(
    private osSrv: ObraSocialService,
    private userSrv: UserService,
    private spinner: NgxSpinnerService,
    private convSrv: ConveniosService
  ) { }

  ngOnInit() {
    this.osSrv.getOSGeren().subscribe( result => {
      this.obras_sociales = result;
      console.log('obra sociales: ' , result);
    });
    
  }

  buscarNew(event){    
    let seleccionado;
    let cod_geren;
    let cod_obra;

    if(this.selectedOS.includes('/')){
      seleccionado = this.selectedOS.includes('/') ? this.selectedOS.replace('/', ' ') : this.selectedOS;

      seleccionado = seleccionado.includes('  ') ? seleccionado.replace('  ', '') : seleccionado;
  
      cod_geren = this.obras_sociales.find(elt => ((elt.descripcion ? elt.descripcion : '') + ' '  + (elt.nombre ? elt.nombre : '')) == seleccionado).cod_geren;
  
      cod_obra = this.obras_sociales.find(elt => ((elt.descripcion ? elt.descripcion : '') + ' ' + (elt.nombre ? elt.nombre : '')) == seleccionado).cod_obra; 
    }

    if(!this.selectedOS.includes('/')){
      seleccionado = this.selectedOS.includes('/') ? this.selectedOS.replace('/', ' ') : this.selectedOS;

      seleccionado = seleccionado.includes('  ') ? seleccionado.replace('  ', '') : seleccionado;
  
      cod_geren = this.obras_sociales.find(elt => ((elt.descripcion ? elt.descripcion : '')  + (elt.nombre ? elt.nombre : '')) == seleccionado).cod_geren;
  
      cod_obra = this.obras_sociales.find(elt => ((elt.descripcion ? elt.descripcion : '') + (elt.nombre ? elt.nombre : '')) == seleccionado).cod_obra; 
    }
    

    
    this.convSrv.getConvenios(cod_geren, cod_obra).subscribe(result => {
      this.convenios = result;
      this.fecha = result[0].fecha;
    })
  }

  buscar(){
    //var geren = this.obras_sociales.filter(e => e.cod_obra == this.selectedOS)[0].cod_geren;
    this.convSrv.getConvenios(this.selectedOS.cod_geren, this.selectedOS.cod_obra).subscribe(result => {
      this.convenios = result;
      this.fecha = result[0].fecha;
    })
  }

  limpiar(){
    this.selectedOS = null;
    this.convenios = null;
  }

  print(){
    window.print();
  }

}
