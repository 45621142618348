import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { NgbDateStruct, NgbDate } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-dateinput',
  templateUrl: './dateinput.component.html',
  styleUrls: ['./dateinput.component.css']
})
export class DateinputComponent implements OnInit {
  @Output() date = new EventEmitter<Date>();
  @Input() label: string;
  model: NgbDate;

  constructor(

  ) {

  }

  ngOnInit() {
  }

  onChangeDate(model) {
    this.date.emit(new Date( model.year + '/' + model.month + '/' + model.day ));
  }

}
