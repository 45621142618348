import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { ConfigurationService } from './configuration.service';
import { HttpClient } from '@angular/common/http';
import { ObraSocial } from '../interfaces/obra-social';
import { Observable } from 'rxjs';
import { Os } from '../interfaces/os';

@Injectable({
  providedIn: 'root'
})
export class ObraSocialService {

  constructor(
    private http: HttpClient,
    private config: ConfigurationService,
    private userSrv: UserService
  ) { }

  getObraSociales(matricula: number): Observable<ObraSocial[]>{
    return this.http.get<ObraSocial[]>(
      this.config.getBaseUrl() + '/invoices/os/' + matricula.toString(),
      { headers: this.config.getHeaders() }
    );
  }

  getAllObraSociales(matricula: number): Observable<Os[]>{
    return this.http.get<Os[]>(
      this.config.getBaseUrl() + '/invoices/os/all/' + matricula.toString(),
      { headers: this.config.getHeaders() }
    );
  }

  getOSGeren(): Observable<any[]>{
    return this.http.get<any[]>(
      this.config.getBaseUrl() + '/invoices/osgeren',
      { headers: this.config.getHeaders() }
    );
  }

  obraSociales(matricula: number): Observable<ObraSocial[]>{
    return this.http.get<ObraSocial[]>(
      this.config.getBaseUrl() + '/invoices/obraSocial/' + matricula.toString(),
      { headers: this.config.getHeaders() }
    );
  }
}
