import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { TipoDocumentosService } from '../services/tipo-documentos.service';
import { UserService } from '../services/user.service';
import { ConfigurationService } from '../services/configuration.service';
import * as FileSaver from "file-saver";
import * as moment from 'moment';
@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.css']
})
export class DownloadsComponent implements OnInit {
  message: string = null;
  label: string = "Período";
  selectedTipoDoc: number = -1;
  tipoDocumentos: any[];
  selectedPeriodo: string = "";
  periodos: any[] = [];
  result: any[];

  constructor(
    private spinner: NgxSpinnerService,
    private docSrv: TipoDocumentosService,
    private userSrv: UserService,
    private configSrv: ConfigurationService
  ) { }

  ngOnInit() {
    this.docSrv.getTiposDeDocumentos().subscribe(result => {
      this.tipoDocumentos = result;
    });
  }

  getPeriodos(): string[]{
    let tipo = this.tipoDocumentos.find( e => e.codigo === this.selectedTipoDoc ).tipo_muestra;
    this.periodos = [];
    this.result = [];
    this.selectedPeriodo = "";

    if(tipo===0){
      //mensual
      this.label = "Año/Mes";
      let now = new Date();
      let endDate = now.getFullYear().toString() + '-' + (now.getMonth()+1).toString() + '-1';
      //this.periodos = this.dateRange('2019-01-01', endDate );
      
      let startDate = moment(endDate).subtract(2, 'years').format("YYYY-MM-DD");

      this.periodos = this.dateRange(startDate, endDate );
    }else{
      //anual
      this.label = "Año";

      /*
      for(let i = 2019; i <= (new Date()).getFullYear(); i++){
        this.periodos.push({
          valor: i.toString()
        })
      }
      */

      let now = new Date();
      let endDate = now.getFullYear().toString() + '-' + (now.getMonth()+1).toString() + '-1';

      let i = Number(moment(endDate).subtract(2, 'years').format("YYYY"));

      for(i; i <= (new Date()).getFullYear(); i++){
        this.periodos.push({
          valor: i.toString()
        })
      }

    }
    this.periodos.reverse();
    return null;
  }

  dateRange(startDate, endDate) {
    var start      = startDate.split('-');
    var end        = endDate.split('-');
    var startYear  = parseInt(start[0]);
    var endYear    = parseInt(end[0]);
    var dates      = [];
  
    for(var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1])-1 : 0;
      for(var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j+1) {
        var month = j+1;
        var displayMonth = month < 10 ? '0'+month : month;
        dates.push({ valor: [i, displayMonth].join('/')});
      }
    }
    return dates;
  }

  buscar(){
    this.spinner.show();
    this.result = []

    this.docSrv.getFiles(this.userSrv.getUser().matricula, this.selectedTipoDoc, this.selectedPeriodo.replace('/','')).subscribe(
      result => {
        this.result = result;
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
      }
    );
  }

  downloadAll(){
    this.result.forEach( r => {
      console.log(r.print);
      if(r.print){
        this.download(r);
      }
    });
  }

  download(r){
    var url = this.getUrl(r.path);
    this.docSrv.getPdf({ url: url } ).subscribe( file => {
      const blob = new Blob([file], {type: 'application/pdf'});
      FileSaver.saveAs(blob, r.nombre);
    },
    error => {
      console.log(error);
    });
    
  }

  getUrl(file: string): string{
    return file;
  }

  getTipo(): string{
    return this.tipoDocumentos.find(t => t.codigo == this.selectedTipoDoc).nombre;
  }

  checkAll(){
    this.result.forEach(r => r.print = !r.print)
  }

  check(r){
    r.print = !r.print
  }

}
