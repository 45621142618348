import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tipoRec'
})
export class TipoRecPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    switch (value){
      case 0:
        return "";
      case 1:
        return 'Edad: ' + args[0] + '%';
        break;
      case 2:
        return "Noct/Fin Semana/Feriado: " + args[0] + '%';
        break;
      case 3:
        return "Neonato: " + args[0] + '%';
        break;
    }
  }

}
