import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { exit } from 'process';
import { ObraSocialService } from 'src/app/services/obra-social.service';
import { OrdersService } from 'src/app/services/orders.service';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import * as  printJS from 'print-js';
import { Chart } from 'chart.js';
import { PrestadorService } from 'src/app/services/prestador.service';
import { InvoicesService } from 'src/app/services/invoices.service';
import { ValidaResult } from 'src/app/interfaces/valida-result';

@Component({
  selector: 'app-facturacion-obra-social',
  templateUrl: './facturacion-obra-social.component.html',
  styleUrls: ['./facturacion-obra-social.component.css']
})
export class FacturacionObraSocialComponent implements OnInit {
  //beggin grafico
  canvas: any;
  ctx: any;
  @ViewChild('mychart',null) mychart: any;
  //end grafico

  desde = null;
  hasta = null;
  user: ValidaResult;
  estadisticas: any[];
  dataX: string[]= ['Obras Sociales'];
  dataY: number[] = [100];
  opcionesGrafico: any[];
  tipoGraficoSelected: number = 1;
  backColor: string[];
  chart: Chart;

  constructor( 
              private orders: OrdersService,
              private obraSocialSrv: ObraSocialService,
              private users: UserService,
              private router: Router,
              private toastr: ToastrService,
              private prestSrv: PrestadorService,    
              private osSrv: ObraSocialService,
              private informeSrv: InvoicesService,
              private modalSrv: NgbModal,
              private spinner: NgxSpinnerService,
    ) 
    { 

    }

  ngOnInit() {
    this.user = this.users.getUser();
    this.opcionesGrafico = this.getOpcionesGraficos();
    this.backColor = [
      'rgba(255, 99, 132, 1)',
      'rgba(54, 162, 235, 1)',
      'rgba(255, 206, 86, 1)',
      'rgba(75, 192, 192, 1)',
      'rgba(153, 102, 255, 1)',
      'rgba(255, 159, 64, 1)',      
     'rgb(255 29 34)',
     '#0000F8',
     'rgb(0 241 54)',
     'rgb(0 175 237)',
     'yellow',
     'brown',
     'pink',
     'grey',
     'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 84, 0.2)',
    'rgba(111, 159, 51, 0.2)',
    'rgba(233, 233, 45, 0.2)',
    'rgba(205, 18, 45, 0.2)',
    
    ]
  }

  ngAfterViewInit() {
    this.canvas = this.mychart.nativeElement;
    this.ctx = this.canvas.getContext('2d');
    

    
  }

  getOpcionesGraficos(){
    return [
      {
        tipo: 1,
        descripcion: "Circular",
      },
      {
        tipo: 2,
        descripcion: "Barras",
      },
      {
        tipo: 3,
        descripcion: "Línea",
      }
    ]
  }

  getGrafico(){
    //destruir grafico para volver a renderizar
    //this.ctx.clear();
    //this.ctx = null;

    

    if(!this.desde || !this.desde){
      Swal.fire({
        text: 'Debe seleccionar una Fecha.',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }        
      });

      return;
    }else{
      let matricula =  this.user.matricula;
      let desde = this.desde;
      let hasta = this.hasta;  

      this.spinner.show();  
      this.informeSrv.getGraficoFacturacionByMedico(matricula, desde, hasta).subscribe( result => {
        this.spinner.hide(); 
        if(this.chart){
          this.chart.destroy();
        }
        
        this.estadisticas = result;
        
        // array con la descripcion de las obras sociales
        this.dataX= this.estadisticas.map(res => res.descripcion);
         // array con los importes de las obras sociales
        this.dataY= this.estadisticas.map(res => res.importe.toFixed(2));

        if(this.tipoGraficoSelected == 1){          
          this.chart = new Chart(this.ctx, {
            type: 'pie',
            data: {
              labels: this.dataX,
              datasets: [
                
                {
                  label: 'Importe',
                  data: this.dataY,
                  backgroundColor: this.backColor,
                  //borderColor: "#007ee7",
                  fill: false,              
                }
              ],          
                
            },
            options:{
              responsive: true,
              legend: {
                position: 'bottom'              
              },
              plugins: {
                color: '#fff',
                anchor: 'end',
                align: 'start',
                offset: -10,
                borderWidth: 2,
                borderColor: '#fff',
                borderRadius: 25,
                backgroundColor: (context) =>{
                  return context.dataset.backgroundColor;
                },
                font: {
                  weight: 'bold',
                  size: '10'
                },
                /*
                formatter: (value) => {
                  return '$' + value.toLocaleString('en-US', { currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2})
                }
                */
              },
            }
          });
        }

        if(this.tipoGraficoSelected == 2){
          this.chart = new Chart(this.ctx, {
            type: 'bar',
            data: {
              labels: this.dataX,
              datasets: [
                
                {
                  data: this.dataY,
                  backgroundColor: this.backColor,
                  //borderColor: "#007ee7",
                  fill: false,              
                }
              ],          
                
            },
            options:{
              legend: {
                display: false              
              },
              scales: {
                xAxes:[
                  {
                  display:true
                  }
                ],
                yAxes: [
                  {
                  display: true
                  }
                ]
              }
            }
          });
        }
        
        if(this.tipoGraficoSelected == 3){
          this.chart = new Chart(this.ctx, {
            type: 'line',
            data: {
              labels: this.dataX,
              datasets: [
                
                {
                  data: this.dataY,
                  backgroundColor: this.backColor,
                  //borderColor: "#007ee7",
                  fill: false,              
                }
              ],          
                
            },
            options:{
              legend: {
                display: false              
              },
              scales: {
                xAxes:[
                  {
                  display:true
                  }
                ],
                yAxes: [
                  {
                  display: true
                  }
                ]
              }
            }
          });
        }

        console.log('x: ', this.dataX);
        console.log('y: ', this.dataY);
      },error => {
        this.spinner.hide();    
  
        Swal.fire({
          text: 'Reintentar Búsqueda.',
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }        
        });
        
      });
    }    
  }

  
}
