import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isnull'
})
export class IsnullPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if ( value == null){
      return ""
    }
    if( value == "null"){
      return ""
    }
    return value;
  }

}
