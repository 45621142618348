import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'grupo'
})
export class GrupoPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    console.log(value);
    if( value === 0 ){
      return null;
    }else{
      return value;
    }

  }

}
