import { Component, OnInit } from '@angular/core';
import { InvoiceDetail } from '../interfaces/invoice-detail';
import { InvoicesService } from '../services/invoices.service';
import { Invoice } from '../interfaces/invoice';
import { ActivatedRoute } from '@angular/router';
import { StarTemplateContext } from '@ng-bootstrap/ng-bootstrap/rating/rating';
import { UserService } from '../services/user.service';
import { ConfigurationService } from '../services/configuration.service';
import { InvoiceDetailItem } from '../interfaces/invoice-detail-item';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-voucher-detail',
  templateUrl: './voucher-detail.component.html',
  styleUrls: ['./voucher-detail.component.css']
})
export class VoucherDetailComponent implements OnInit {
  detalles: InvoiceDetail[];
  hideme = []; 
  tipo: string;
  letra: string;
  cod: string;
  nro: string;
  Index: any; 

  constructor(
    private invoceSrv: InvoicesService,
    private route: ActivatedRoute,
    private userSrv: UserService,
    private config: ConfigurationService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(queryParam => {
      this.tipo = queryParam['tipo'];
      this.letra = queryParam['letra'];
      this.cod = queryParam['cod'];
      this.nro = queryParam['nro'];
      this.spinner.show();

      this.invoceSrv.getInvoiceDetails(this.tipo, this.letra, this.cod, this.nro).subscribe( result => {
        this.detalles = result;
        this.spinner.hide();
      });

    });
  }

  getPdfUri(url): string{
   return this.config.getBaseUrl() + '/' + url;
  }

  print(){
    window.print();
  }

  public showItems(index) {  
    this.hideme[index] = !this.hideme[index];  
    this.Index = index;
  } 


}
