import { UserService } from './../services/user.service';
import { IntentosResult } from './../interfaces/intentos-result';
import { LoginService } from './../services/login.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ValidaResult } from '../interfaces/valida-result';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  matricula: string = '';
  password: string = '';
  message: string = null;

  constructor(
    private router: Router,
    private loginSrv: LoginService,
    private userSrv: UserService
  ) { }

  ngOnInit() {
    this.userSrv.setUser(null);
  }

  login() {
    let mat: number;
    let contador: string;
    
    if (this.isNumber( this.matricula.charAt(0) ) ){
      mat =  +this.matricula;
      contador = null;
    } else {
      mat =  +this.matricula.substring(1);
      contador = this.matricula.charAt(0);
    }

    this.loginSrv.valida(mat, this.password, contador).subscribe( validaResult => {
      if ( validaResult ) {        
        // primera vez que ingresa con la matricula
        if( mat.toString() === this.password ) {          
          let usr: ValidaResult = {
            matricula: mat,
            nombre: '',
            indice: this.password,
            tipo: contador
          }
          this.userSrv.setUser(usr);
          this.router.navigate(['change'], {  });
          return
        }

        this.userSrv.setUser(validaResult);
        console.log(validaResult);

        this.loginSrv.getIntentos(mat, contador).subscribe( intentosReult => {
          console.log(intentosReult.cambio);

          if ( intentosReult.cambio === 'N') {
            this.router.navigate(['home'], {  });
          } else {
            this.router.navigate(['change'], {  });
          }
        });
      }
    }, error => {
      this.message = 'La matrícula o la contraseña son incorrectas.';
    });
  }

  isNumber(value: string | number): boolean
  {
    return ((value != null) &&
            (value !== '') &&
            !isNaN(Number(value.toString())));
  }


}






