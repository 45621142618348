import { UserService } from './../services/user.service';
import { Router } from '@angular/router';
import { Component, ViewChild } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { CargaLotesService } from '../services/carga-lotes.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  isExpanded = false;
  file: File = null;
  fileName: string = '';
  detalle: any = '';

  @ViewChild('modal', null) modal: NgbModal;

  constructor(
    private router: Router,
    private userSrv: UserService,
    private modalSrv: NgbModal,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,    
    private cargaLotesSrv: CargaLotesService
  ) { }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  isTipoOQ(): boolean {
    const user = this.userSrv.getUser();
    if(user.tipo !== null){
      return user.tipo.toLowerCase() === 'o' || user.tipo.toLowerCase() === 'q';
    }else{
      return false;
    }
  }

  isTipoMGS(): boolean {
    const user = this.userSrv.getUser();
    if(user.tipo !== null){
      return user.tipo.toLowerCase() === 'm' || user.tipo.toLowerCase() === 'g' || user.tipo.toLowerCase() === 's';
    }else{
      return false;
    }
  }

  isTipoMGSC(): boolean {
    const user = this.userSrv.getUser();
    if(user.tipo !== null){
      return user.tipo.toLowerCase() === 'm' || user.tipo.toLowerCase() === 'g' || user.tipo.toLowerCase() === 's' || user.tipo.toLowerCase() === 'c';
    }else{
      return false;
    }
  }

  isTipoMG(): boolean {
    const user = this.userSrv.getUser();
    if(user.tipo !== null){
      return user.tipo.toLowerCase() === 'm' || user.tipo.toLowerCase() === 'g';
    }else{
      return false;
    }
  }

  isTipoA(): boolean {
    const user = this.userSrv.getUser();
    if(user.tipo !== null){
      return user.tipo.toLowerCase() === 'a';
    }else{
      return false;
    }
  }

  openModal(content) {
    let options: NgbModalOptions = {
      size: 'xl',
      centered: true,
      backdrop : 'static',
      keyboard : false
    }

    this.modalSrv.open(content, options).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(reason);
    });
  }

  open(){
    this.file =null;
    this.fileName = null;
    this.detalle = '';
    this.openModal(this.modal); 
  }

  uploadFile(event){
    console.log(event);  
    const file: File = event.target.files[0];        
    if(file){
      this.file =file;
      this.fileName = file.name;
    }  
  }

  cleanFile(){
    this.file =null;
    this.fileName = null;
    this.detalle = '';
  }

  upFile(){    
    if(!this.file){
      this.toastr.error('Debe subir un Archivo.', 'Carga de Lotes', { closeButton: true, timeOut: 6000 });

      Swal.fire({
        text: 'Debe subir un Archivo.',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }        
      });
      return
    }else{        
      if (!this.fileName.startsWith('0')) {
        let id: any = this.fileName.substring(0, this.fileName.indexOf('_'));
        id = !isNaN(id) ? Number(id) : null;

        if(isNaN(id)){
          Swal.fire({
            text: 'Error en la carga del archivo, validación incorrecta del nombre.',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            }        
          });
          return
        }
        debugger
        this.cargaLotesSrv.deleteLote(id).subscribe(result => {
          this.cargaLote();

        }, error => {  
          Swal.fire({
            text: 'Error en el borrado del Lote.',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            }        
          });
        })
        
      } else {
        this.cargaLote();
      }
    }    
  }

  cargaLote(){
    this.spinner.show();
    this.detalle = 'Cargando...';
    const formData = new FormData();
    formData.append("file", this.file);  

    this.cargaLotesSrv.upFileXml(formData).subscribe(result => {
      if(result){
        this.spinner.hide();    
        console.log('resultado: ', result);
        this.detalle = (result.lineasProcesadas ? ('Lineas Procesadas: ' + result.lineasProcesadas) : '') + 
        '\n' +
        ((result.cantidadLineasError == 0 || !result.cantidadLineasError) ? 'Cantidad de Lineas con errores: Ninguna' : ('Cantidad de Lineas con Error: ' + result.cantidadLineasError)) +
        '\n' +
        (result.lineasErradas.length == 0 ? 'Lineas con errores: Ninguna' : ('Lineas con Error: ' + result.lineasErradas));        
      
        Swal.fire({
          text: 'El Archivo se subió con éxito.',
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }        
        });
      }

    }, error => {      
      this.spinner.hide();          
      Swal.fire({
        text: 'Hubo un error en la carga del Archivo, vuelva a intentar.',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }        
      });

      console.log('Contenido de error:', error);
      if(error){
        this.detalle = error.message;
      }
    });
  }

}
