import { Component, OnInit } from '@angular/core';
import { Accreditation } from '../interfaces/accreditation';
import { InvoicesService } from '../services/invoices.service';
import { UserService } from '../services/user.service';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from '../services/configuration.service';
import { BankAccreditation } from '../interfaces/bank-accreditation';
import { OrdenPago } from '../interfaces/orden-pago';
import { Banco } from '../interfaces/banco';
import { Dictionary } from "lodash";
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-accreditations',
  templateUrl: './accreditations.component.html',
  styleUrls: ['./accreditations.component.css']
})
export class AccreditationsComponent implements OnInit {
  desde = null;
  hasta = null;
  accreditations: Accreditation[];
  totalesGrupo: Accreditation[];
  bankAccreditation: BankAccreditation[];
  importeTotal: number = 0;
  ivaTotal: number = 0;
  retIGTotal: number = 0;
  retIBTotal: number = 0;
  gastAdminTotal: number = 0;
  gastVarTotal: number = 0;
  netoTotal: number = 0;
  solapa: number = 1;
  bancos: Banco[];
  ordenes: Dictionary<string, OrdenPago> = {};
  porGrupo: number = 1;
  message: string = null;

  constructor(
    private invociceSrv: InvoicesService,
    private userSrv: UserService,
    private http: HttpClient,
    private configSrv: ConfigurationService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
  }

  setMessage(message){
    this.message = message;
    setTimeout(() =>{this.message = null;}, 3000);
  }

  getDesde(): string {
    if(this.desde===null){
      this.setMessage("Debe ingresar una fecha desde.");
      return null;
    }
    
    return this.desde;
  }

  getHasta(): string {
    if(this.hasta===null){
      this.setMessage("Debe ingresar una fecha hasta.");
      return null;
    }
    return this.hasta;
  }

  getTotalImporte(){
    if( this.accreditations.length != 0 ){
      if( this.porGrupo === 1 ){ // fecha
        let importeTotal: number = 0;
        let ivaTotal: number = 0;
        let retIGTotal: number = 0;
        let retIBTotal: number = 0;
        let gastAdminTotal: number = 0;
        let gastVarTotal: number = 0;
        let netoTotal: number = 0;
  
        for (let accreditation of this.accreditations){
          importeTotal += accreditation.importe;
          ivaTotal += accreditation.iva;
          retIGTotal += accreditation.imp_ret_ig;
          retIBTotal += accreditation.imp_ret_ib;
          gastAdminTotal += accreditation.imp_gtos_admin;
          gastVarTotal += accreditation.gtos_varios;
          netoTotal += accreditation.neto;
        }
  
        this.importeTotal = importeTotal;
        this.ivaTotal = ivaTotal;
        this.retIGTotal = retIGTotal;
        this.retIBTotal = retIBTotal;
        this.gastAdminTotal = gastAdminTotal;
        this.gastVarTotal = gastVarTotal;
        this.netoTotal = netoTotal;
      }else{ // grupo
        var ultGrupo: number = undefined;

        let importeTotal: number = 0;
        let ivaTotal: number = 0;
        let retIGTotal: number = 0;
        let retIBTotal: number = 0;
        let gastAdminTotal: number = 0;
        let gastVarTotal: number = 0;
        let netoTotal: number = 0;
        let i: number = 0;
        let len = this.accreditations.length;
        let ultMatricula: number;
  
        for (const { index, value } of this.accreditations.map((value, index) => ({ index, value }))) {
          if( ultGrupo === undefined ){
            ultGrupo = value.grupo;
            ultMatricula = value.matricula;
          }

          if( ultGrupo !== value.grupo && ultGrupo !== undefined ){
            ultGrupo = value.grupo;

            let totales =  {
              tipo_op: "",
              letra_op: "",
              cod_op: "",
              nro_op: "",
              fecha_op: null,
              grupo: null,
              nombre_grupo: "",
              importe: importeTotal,
              iva: ivaTotal,
              imp_ret_ig: retIGTotal,
              imp_ret_ib: retIBTotal,
              gtos_varios: gastVarTotal,
              imp_gtos_admin: gastAdminTotal,
              neto: netoTotal,
              tipo_liq: "",
              matricula: null,
              nombre_op: "",
              nombre_liq: "",
              nombre_ret: "",
            };

            this.accreditations.splice(index + i++, 0, totales)

            importeTotal = 0;
            ivaTotal = 0;
            retIGTotal = 0;
            retIBTotal = 0;
            gastAdminTotal = 0;
            gastVarTotal = 0;
            netoTotal = 0;
          }

          if( ultGrupo === 0 &&  ultMatricula !== value.matricula ){
            ultMatricula = value.matricula;
            ultGrupo = value.grupo;

            let totales =  {
              tipo_op: "",
              letra_op: "",
              cod_op: "",
              nro_op: "",
              fecha_op: null,
              grupo: null,
              nombre_grupo: "",
              importe: importeTotal,
              iva: ivaTotal,
              imp_ret_ig: retIGTotal,
              imp_ret_ib: retIBTotal,
              gtos_varios: gastVarTotal,
              imp_gtos_admin: gastAdminTotal,
              neto: netoTotal,
              tipo_liq: "",
              matricula: null,
              nombre_op: "",
              nombre_liq: "",
              nombre_ret: "",
            };

            this.accreditations.splice(index + i++, 0, totales)

            importeTotal = 0;
            ivaTotal = 0;
            retIGTotal = 0;
            retIBTotal = 0;
            gastAdminTotal = 0;
            gastVarTotal = 0;
            netoTotal = 0;
          }

          importeTotal += value.importe;
          ivaTotal += value.iva;
          retIGTotal += value.imp_ret_ig;
          retIBTotal += value.imp_ret_ib;
          gastAdminTotal += value.imp_gtos_admin;
          gastVarTotal += value.gtos_varios;
          netoTotal += value.neto;

          console.log("index: " + index);
          console.log("len: " + len);
          if(index === len -1 ){
            let totales =  {
              tipo_op: "",
              letra_op: "",
              cod_op: "",
              nro_op: "",
              fecha_op: null,
              grupo: null,
              nombre_grupo: "",
              importe: importeTotal,
              iva: ivaTotal,
              imp_ret_ig: retIGTotal,
              imp_ret_ib: retIBTotal,
              gtos_varios: gastVarTotal,
              imp_gtos_admin: gastAdminTotal,
              neto: netoTotal,
              tipo_liq: "",
              matricula: null,
              nombre_op: "",
              nombre_liq: "",
              nombre_ret: "",
            };

            this.accreditations.splice(index + ++i, 0, totales)

            
          }
                    
        }
  
      }
      

    }
  }

  getAcreditaciones() {
    var orden: number;
    orden = this.porGrupo;
    var desde = this.getDesde()
    var hasta = this.getHasta()
    
    this.spinner.show();

    this.invociceSrv.getAccreditations(this.userSrv.getUser().matricula, desde, hasta, orden).subscribe(
      result => {
        this.accreditations = result;
        this.getTotalImporte();
        this.spinner.hide();
      },
      error =>{
        this.spinner.hide();
      }
    );

    this.invociceSrv.getBankAccreditation(this.userSrv.getUser().matricula, desde, hasta).subscribe(
      result => {
        this.bankAccreditation = result;
        this.procesarDatos();
        this.spinner.hide();
      },
      error =>{
        this.spinner.hide();
      }
    );
  }

  buscar() {
    this.getAcreditaciones();
  }

  esTipoContadorS(): boolean{
    return this.userSrv.getUser().tipo.toLowerCase() === 's';
  }

  procesarDatos() {

    // bancos
    var last: Banco;
    this.bancos = new Array<Banco>();

    for( let ele of this.bankAccreditation){
      
      if(last === undefined ){
        var nuevo = {
          banco: ele.banco,
          tipo: ele.tipo_cta,
          cuenta: ele.nro_cuenta
        };
        this.bancos.push(nuevo);
        last = nuevo;
      }else{
        if( !(last.banco === ele.banco &&  last.cuenta === ele.nro_cuenta) ){
          var nuevo = {
            banco: ele.banco,
            tipo: ele.tipo_cta,
            cuenta: ele.nro_cuenta
          };
          this.bancos.push(nuevo);
          last = nuevo;
        }
      }
      
    }

    // ordenes
    var ordenes: Dictionary<string, OrdenPago> = {};
    
    for( let ele of this.bankAccreditation){
      var ord = ele.tipo_op + ' ' + ele.letra_op + ' ' + ele.cod_op + '-' + ele.nro_op;
      var orden = ordenes[ord];
      var orden_nueva;

      console.log(orden);

      if( orden !== undefined ){
        var bancos = new Array<Banco>();
        bancos = orden.bancos;

        console.log(bancos);
        
        var banco_nuevo = {
          banco: ele.banco,
          tipo: ele.tipo_cta,
          cuenta: ele.nro_cuenta,
          monto: ele.importe_data
        };

        bancos.push(banco_nuevo);

        orden_nueva = {
          tipo: ele.tipo_op,
          letra: ele.letra_op,
          cod: ele.cod_op,
          nro: ele.nro_op,
          fecha: ele.fecha_pago,
          bancos: bancos,
        };


      }else{
        var bancos = new Array<Banco>();

        var banco_nuevo = {
          banco: ele.banco,
          tipo: ele.tipo_cta,
          cuenta: ele.nro_cuenta,
          monto: ele.importe_data
        };

        bancos.push(banco_nuevo);

        orden_nueva = {
          tipo: ele.tipo_op,
          letra: ele.letra_op,
          cod: ele.cod_op,
          nro: ele.nro_op,
          fecha: ele.fecha_pago,
          bancos: bancos,
        };

      }

      ordenes[ord] = orden_nueva;
    }

    console.log(ordenes);

    this.ordenes = ordenes;

    // totales
    for(let banco of this.bancos){
      var monto = 0;

      for( let ele of this.bankAccreditation){
        if( banco.banco === ele.banco &&  banco.cuenta === ele.nro_cuenta ){
          monto += ele.importe_data;
        }
      }

      banco.monto = monto;

    }

    console.log(this.bancos);

  }

  print(){
    window.print();
  }

  getOPUrl(accreditation){
    return this.configSrv.getBaseUrl() + '/' + accreditation.nombre_op;
  }
  getLiqUrl(accreditation){
    return this.configSrv.getBaseUrl() + '/' + accreditation.nombre_liq;
  }
  getRetIGUrl(accreditation){
    return this.configSrv.getBaseUrl() + '/' + accreditation.nombre_ret;
  }
}
