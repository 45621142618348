import { Component, OnInit, ViewChild } from '@angular/core';
import { ObraSocialService } from '../services/obra-social.service';
import { UserService } from '../services/user.service';
import { OrdersService } from '../services/orders.service';
import { Prestador } from '../interfaces/prestador';
import { PrestadorService } from '../services/prestador.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Os } from '../interfaces/os';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import * as  printJS from 'print-js';
import { PrintService } from '../services/print.service';
import { Chart } from 'chart.js';
import { InvoicesService } from '../services/invoices.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {

  paciente: string;
  desde = null;
  hasta = null;
  selectedOS: number;
  obras_sociales: Os[];
  selectedPrestador: number;
  prestadores: Prestador[];
  filter: number;
  ordenes: any[];
  detalle: boolean = false;
  message: string = null;
  selectedTipo: number;
  valor: string = null;
  user: string;
  matricula: number;
  fileName: string;
  @ViewChild('modalToast', {read: '', static: true}) modalToast: NgbModal;
  @ViewChild('modalGrafico', null) modalGrafico: NgbModal;

  constructor(
    private osSrv: ObraSocialService,
    private userSrv: UserService,
    private orderSrv: OrdersService,
    private prestSrv: PrestadorService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modalSrv: NgbModal,
    private printSrv: PrintService,
    private invoiceSrv: InvoicesService,
  ) {
    this.osSrv.getAllObraSociales(this.userSrv.getUser().matricula).subscribe( result => {
      this.obras_sociales = result;
      console.log(result);
    });

    this.prestSrv.getPrestadores(this.userSrv.getUser().matricula).subscribe( result => {
      this.prestadores = result;
      console.log(result);
    });
  }

  ngOnInit() {
    this.filter = 1;
    this.selectedOS = -1;
    this.selectedPrestador = -1;
    this.selectedTipo = 0;
    this.user = (this.userSrv.getUser()).nombre;
    this.matricula = (this.userSrv.getUser()).matricula;
    console.log('usuario: ', this.userSrv.getUser());
    
  }

  optionChange(){

  }

  getDesde(): string {
    if(this.desde===null){
      this.setMessage("Debe ingresar una fecha desde.");
      return null;
    }
    
    return this.desde;
  }

  getHasta(): string {
    if(this.hasta===null){
      this.setMessage("Debe ingresar una fecha hasta.");
      return null;
    }
    
    return this.hasta;
  }

  setMessage(message){
    this.message = message;
    setTimeout(() =>{this.message = null;}, 3000);
  }

  buscar() {
    let fecha1 = moment(this.desde);
    let fecha2 = moment(this.hasta);

    let diferencia = fecha2.diff(fecha1, 'days');


    if(diferencia > 31){
      Swal.fire({
        text: 'La Fecha debe ser menor a 1 mes.',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }        
      });

      return
    }

    console.log(fecha2.diff(fecha1, 'days'), 'dias de diferencia');  
    
    if((this.valor === null || this.valor === '') && this.userSrv.getUser().tipo === 's' && this.filter !=5){
      var msg = this.selectedTipo === 0 ? 'Debe ingresar un valor para la matrícula.' : 'Debe ingresar un valor para el grupo.'
      this.setMessage(msg);
      return
    }
    if(this.filter!=2){
      var desde = this.getDesde()
      var hasta = this.getHasta()

      if(desde === null || hasta === null){
        return
      }

      let desdeAux = new Date( this.desde.year + '/' + this.desde.month + '/' + this.desde.day )
      let hastaAux = new Date( this.hasta.year + '/' + this.hasta.month + '/' + this.hasta.day )

      var dias = (hastaAux.getTime() - desdeAux.getTime()) / 1000 / 60 / 60 / 24

      if(dias > 31){
        this.setMessage('El rango de fechas supera los 31 días.');
        return;
      }
    }
    
    var matricula = this.userSrv.getUser().matricula;
    this.spinner.show();
    console.log(new Date())
   
    switch (this.filter){
      case 1:{
        this.orderSrv.getOrdersByDate(matricula, desde, hasta, this.detalle, this.selectedTipo, this.valor).subscribe(
          resutl => {
            this.ordenes = resutl;
            this.spinner.hide();
            console.log(new Date())
          },
          error =>{            
            this.ordenes = null;
            this.spinner.hide();
            /*
            Swal.fire({
              title:'Reintentar',
              //showCancelButton: true,
              //cancelButtonText: 'Cerrar',
              //cancelButtonColor: 'red',
              showConfirmButton: false,
              //icon: 'error',
              width: 500,
              allowOutsideClick: true,
              allowEscapeKey: true,
              background: 'lightgrey'
            })
            */
            Swal.fire({
              text: 'Reintentar',
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              }
            })
            console.log(new Date())
          }
        );
        break;
      }
      case 2:{
        this.orderSrv.getOrdersByPaciente(matricula, this.paciente, this.detalle, this.selectedTipo, this.valor).subscribe(
          resutl => {
            this.ordenes = resutl;
            this.spinner.hide();

           
            console.log(new Date())
          },
          error =>{            
            this.ordenes = null;
            /*
            Swal.fire({
              title:'Reintentar',
              //showCancelButton: true,
              //cancelButtonText: 'Cerrar',
              //cancelButtonColor: 'red',
              showConfirmButton: false,
              //icon: 'error',
              width: 500,
              allowOutsideClick: true,
              allowEscapeKey: true,
              background: 'lightgrey'
            })
            */
            Swal.fire({
              text: 'Reintentar',
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              }
            })
            this.spinner.hide();
            console.log(new Date())
          }
        );
        break;
      }
      case 3:{
        this.orderSrv.getOrdersByOS(matricula, desde, hasta, this.selectedOS, this.detalle, this.selectedTipo, this.valor).subscribe(
          resutl => {
            this.ordenes = resutl;
            this.spinner.hide();
            
            console.log(new Date())
          },
          error =>{            
            this.ordenes = null;
            this.spinner.hide();
            Swal.fire({
              text: 'Reintentar',
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              }
            })
            /*
            Swal.fire({
              title:'Reintentar',
              //showCancelButton: true,
              //cancelButtonText: 'Cerrar',
              //cancelButtonColor: 'red',
              showConfirmButton: false,
              //icon: 'error',
              width: 500,
              allowOutsideClick: true,
              allowEscapeKey: true,
              background: 'lightgrey'
            })
            */
            console.log(new Date())
          }
        );
        break;
      }
      case 4:{
        this.orderSrv.getOrdersByPrestador(matricula, desde, hasta, this.selectedPrestador, this.detalle, this.selectedTipo, this.valor).subscribe(
          resutl => {
            this.ordenes = resutl;
            this.spinner.hide();
            
            console.log(new Date())
          },
          error =>{            
            this.ordenes = null;
            this.spinner.hide();
            Swal.fire({
              text: 'Reintentar',
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              }
            })
            console.log(new Date())
          }
        );
        break;
      }
      case 5:{
        this.invoiceSrv.ordenesNoComputadas(matricula, desde, hasta).subscribe(
          resutl => {
            this.ordenes = resutl;
            this.spinner.hide();
            console.log(new Date())
          },
          error =>{
            
            this.ordenes = null;
            this.spinner.hide();
            console.log(error);            

            if(error){
              Swal.fire({
                text: 'Proceso No Habilitado',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp'
                }
              })
            }

            /*
            if(error.error.includes('Proceso No Habilitado')){
              Swal.fire({
                text: 'Proceso No Habilitado',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp'
                }
              })
            }else{
              Swal.fire({
                text: 'Reintentar',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp'
                }
              })
            }
            */
            
          }
        );
        break;
      }
    }
  }  

  print(){   
    
    printJS({
      printable: 'print_ordenes',
      type: 'html',
      //style: this.printSrv.getTableStyle(),
      scanStyles: false
    })
  }

  getRecargo(orden){

    if( orden.recargo_edad!=null && orden.recargo_edad!=0 && orden.recargo_urg!=null && orden.recargo_urg!=0){
      console.log()
      return "Edad: " + orden.recargo_edad + "%" + ' + ' + "Noct/Fin Semana/Feriado: " + orden.recargo_urg + "%";
    }

    if( orden.recargo_edad!=null && orden.recargo_edad!=0 && (orden.recargo_urg == null || orden.recargo_urg == 0)){
      console.log()
      return "Edad: " + orden.recargo_edad + "%";
    }

    if( orden.recargo_urg!=null && orden.recargo_urg!=0 && (orden.recargo_edad == null || orden.recargo_edad == 0)){
      return "Noct/Fin Semana/Feriado: " + orden.recargo_urg + "%";
    }

    /*
    if( orden.recargo_neonato!=null && orden.recargo_neonato!=0){
      return "Neonato: " + orden.recargo_neonato + "%";
    }
    */

    return "";
  }

  detalle1(orden): boolean{
    //(ítem>= 1 and ítem<=999 y orden = 0)
    return orden.item >=1 && orden.item <=999 && orden.orden ==0;
  }

  detalle2(orden): boolean{
    //(ítem >= 1000 y orden = 0)
    return orden.item >=1000 && orden.orden ==0;
  }

  detalle3(orden): boolean{
    //(ítem=0 y orden = 1)
    return orden.orden ==1;
  }

  isVisible(orden, columnName): boolean{
    if(!this.detalle1(orden) && !this.detalle2(orden) && !this.detalle3(orden)){
      return true;
    }
    if(this.detalle1(orden)){
      switch (columnName){
        case 'nro_orden':
          return true;
          break;
        case 'matricula':
          return false;
          break;
        case 'nombre':
          return false;
          break;
        case 'fecha_practica':
          return false;
          break;
        case 'fecha_op':
          return false;
          break;
        case 'paciente':
          return true;
          break;
        case 'sigla':
          return false;
          break;
        case 'descripcion':
          return true;
          break;
        case 'recargo':
          return false;
          break;
        case 'importe_fact':
          return false;
          break;
        case 'credito_total__debito_total':
          return true;
          break;
        case 'neto_total':
          return false;
          break;
        case 'importe':
          return true;
          break;
        case 'iva_fact_1':
          return false;
          break;
        case 'grupo':
          return false;
          break;
        case 'porcentaje':
          return false;
          break;
        case 'nro_liq':
          return false;
          break;
      }
    }
    if(this.detalle2(orden)){
      switch (columnName){
        case 'nro_orden':
          return false;
          break;
        case 'matricula':
          return false;
          break;
        case 'nombre':
          return false;
          break;
        case 'fecha_practica':
          return false;
          break;
        case 'fecha_op':
          return false;
          break;
        case 'paciente':
          return true;
          break;
        case 'sigla':
          return false;
          break;
        case 'descripcion':
          return false;
          break;
        case 'recargo':
          return false;
          break;
        case 'importe_fact':
          return false;
          break;
        case 'credito_total__debito_total':
          return true;
          break;
        case 'neto_total':
          return false;
          break;
        case 'importe':
          return false;
          break;
        case 'iva_fact_1':
          return false;
          break;
        case 'grupo':
          return false;
          break;
        case 'porcentaje':
          return false;
          break;
        case 'nro_liq':
          return false;
          break;
      }
    }
    if(this.detalle3(orden)){
      switch (columnName){
        case 'nro_orden':
          return true;
          break;
        case 'matricula':
          return false;
          break;
        case 'nombre':
          return false;
          break;
        case 'fecha_practica':
          return false;
          break;
        case 'fecha_op':
          return false;
          break;
        case 'paciente':
          return true;
          break;
        case 'sigla':
          return false;
          break;
        case 'descripcion':
          return true;
          break;
        case 'recargo':
          return false;
          break;
        case 'importe_fact':
          return false;
          break;
        case 'credito_total__debito_total':
          return true;
          break;
        case 'neto_total':
          return false;
          break;
        case 'importe':
          return true;
          break;
        case 'iva_fact_1':
          return false;
          break;
        case 'grupo':
          return true;
          break;
        case 'porcentaje':
          return true;
          break;
        case 'nro_liq':
          return true;
          break;
      }
    }
  }
  isTipoS(): boolean{
    return this.userSrv.getUser().tipo === 's';
  }  

  validarFecha(){
    let fecha1 = moment(this.desde);
    let fecha2 = moment(this.hasta);

    let diferencia = fecha2.diff(fecha1, 'days');


    if(diferencia > 31){
      Swal.fire({
        text: 'La Fecha debe ser menor a 1 mes.',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }        
      })
    }

    console.log(fecha2.diff(fecha1, 'days'), 'dias de diferencia');  
  }

  excelExport(){
    if(!this.ordenes){
      Swal.fire({
        text: 'No hay datos para exportar al Excel.',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }        
      })
      return
    }

    this.fileName ='ordenes-pagadas-' + this.user + this.matricula.toString() + '.xlsx';
    //this.excelSrv.export(this.ordenes); 
    let element = document.getElementById('printTable');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
 
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
 
    /* save to file */  
    XLSX.writeFile(wb, this.fileName);
  }

  exportarExcelConCabecera(){
    /*
    this.dataPrintCtaCte = [];

    let cabecera = {
      Cliente: this.nombreCliente,  
      Desde: this.desde,
      Hasta: this.hasta,      
      Saldo_Final: this.total, 
    }

   
    this.grid.forEachNodeAfterFilterAndSort((rowNode, index)=>{
      this.dataPrintCtaCte.push(this.getNewDataRowCtaCte(rowNode.data))
  })  
    setTimeout(()=>{
      this.excelSrv.exportWithHeader(this.dataPrintCtaCte, cabecera) 
     }, 250)
    */  
  }

  openModalGrafico(){
    this.openModal(this.modalGrafico); 
  }

  openModal(content) {
    let options: NgbModalOptions = {
      size: 'xl',
      centered: true,
      backdrop : 'static',
      keyboard : false
    }

    this.modalSrv.open(content, options).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(reason);
    });
  }

  
}
