import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class NomencladorService {

  constructor(
    private http: HttpClient,
    private config: ConfigurationService,
  ) { }

  getNomenclador(criterio: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.config.getBaseUrl() + '/nomenclador/nom',
      criterio,
      { headers: this.config.getHeaders() }
    )
  }

  
}
