import { Component } from '@angular/core';

@Component({
  selector: 'app-novedades',
  templateUrl: './novedades.component.html',
  styleUrls: ['./novedades.component.css']
})
export class NovedadesComponent {


  constructor() { 
  }

  ngOnInit() {
  }

  

}
