import { ConfigurationService } from './../services/configuration.service';
import { HttpClient } from '@angular/common/http';
import { UserService } from './../services/user.service';
import { InvoicesService } from './../services/invoices.service';
import { Component, OnInit } from '@angular/core';
import { Invoice } from '../interfaces/invoice';
import { Router } from '@angular/router';
import { ObraSocial } from '../interfaces/obra-social';
import { ObraSocialService } from '../services/obra-social.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.css']
})
export class VoucherComponent implements OnInit {
  showLabel: boolean = true;
  desde = null;
  hasta = null;
  facturas: any;
  obras_sociales: ObraSocial[];
  newObras_sociales: ObraSocial[];
  selectedOS: number = -1;
  message: string = null;

  constructor(
    private invociceSrv: InvoicesService,
    private userSrv: UserService,
    private http: HttpClient,
    private configSrv: ConfigurationService,
    private router: Router,
    private osSrv: ObraSocialService,
    private spinner: NgxSpinnerService
  ) {
    
  }

  ngOnInit() {
   this.getObraSociales(); 
   this.getObraSocialesNew();
  }
  
  getObraSociales(){
    this.osSrv.getObraSociales(this.userSrv.getUser().matricula).subscribe( result => {
      this.obras_sociales = result;
      console.log(result);
    });
  }

  setMessage(message){
    this.message = message;
    setTimeout(() =>{this.message = null;}, 3000);
  }

  getDesde(): string {
    if(this.desde===null){
      this.setMessage("Debe ingresar una fecha desde.");
      return null;
    }
    return this.desde;
  }

  gethasta(): string {
    if(this.hasta===null){
      this.setMessage("Debe ingresar una fecha hasta.");
      return null;
    }
    return this.hasta;
  }

  getFacturas() {
    var desde = this.getDesde();
    var hasta = this.gethasta();

    if(desde === null || hasta === null){
      return;
    }

    this.spinner.show();

    this.invociceSrv.getInvoices(this.userSrv.getUser().matricula, desde, hasta, this.selectedOS).subscribe( 
      result => {
        this.facturas = result;
        for(let f of this.facturas){
          this.generateZip(f);
        }
        this.spinner.hide();
    },
      error => {
        this.spinner.hide();
      }
    );
  }

  buscar() {
    this.showLabel = false;
    this.getFacturas();
  }

  getZipUri(factura: Invoice): string {
    let uri: string;
    uri = this.configSrv.getBaseUrl() + '/PDF/Imagenes/zip/';
    uri += factura.tipo + factura.letra + factura.cod + factura.nro + '.zip';
    return uri;
  }

  generateZip(factura: Invoice) {
    this.invociceSrv.getImages(factura.tipo, factura.letra, factura.cod, factura.nro).subscribe(result => {
      console.log(result);
    });
  }

  getPdfUri(factura: Invoice): string{
    let uri: string;
    uri = this.configSrv.getBaseUrl() + '/' + factura.nombre_archivo;
    return uri;
  }

  copyImg(factura){
    this.invociceSrv.downloadImages(
      factura.tipo,
      factura.letra,
      factura.cod,
      factura.nro
    ).subscribe( res => {
      console.log(res);
    });
  }

  print(){
    window.print();
  }

  onChangeDate(){
    console.log(this.desde)
    console.log(this.hasta)
  }

  getObraSocialesNew(){
    let matricula = this.userSrv.getUser().matricula;  

    this.spinner.show();

    this.osSrv.obraSociales(matricula).subscribe( 
      result => {
        this.facturas = result;
        for(let f of this.facturas){
          this.generateZipInicio(f);
        }
        this.spinner.hide();
    },
      error => {
        this.spinner.hide();
      }
    );
  }

  generateZipInicio(factura: Invoice) {    
    let matricula = this.userSrv.getUser().matricula;  

    this.invociceSrv.getImagesInicio(factura.tipo, factura.letra, factura.cod, factura.nro, matricula).subscribe(result => {
      console.log(result);
    });
  }
}
