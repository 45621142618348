import { ChangeRequest } from './../interfaces/change-request';
import { LoginService } from './../services/login.service';
import { UserService } from './../services/user.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-change',
  templateUrl: './change.component.html',
  styleUrls: ['./change.component.css']
})
export class ChangeComponent implements OnInit {

  password: string = '';
  passwordNew1: string = '';
  passwordNew2: string = '';
  message: string = null;

  constructor(
    private router: Router,
    private userSrv: UserService,
    private loginSrv: LoginService
  ) { }

  ngOnInit() {
  }

  cambiar() {
    if ( this.validar() ) {
      const usr = this.userSrv.getUser()
      const request: ChangeRequest = {
        matricula: usr.matricula,
        password: this.password,
        newPassword: this.passwordNew1,
        tipoContador: usr.tipo == null ? "" : usr.tipo
      };

      this.loginSrv.cambiar(request).subscribe( result => {
        console.log(result.respuesta);
        if ( result.respuesta === 1 ) {
          this.router.navigate(['/'], { });
        }
      }, error => {
        this.message = 'No se ha podido realizar el cambio de contraseña.';
      });
    }
  }

  validar(): boolean {
    const currentUser = this.userSrv.getUser();

    if ( this.password.trim() === '' || this.passwordNew1.trim() === '' || this.passwordNew2.trim() === '' ) {
      this.message = 'Debe ingresar todos los campos.';
      return false;
    }

    if ( currentUser.indice !== this.password ) {
      this.message = 'La contraseña actual no coincide.';
      return false;
    }

    if ( this.passwordNew1 !== this.passwordNew2 ) {
      this.message = 'Las contraseñas nuevas no coinciden.';
      return false;
    }

    if ( this.passwordNew1.length < 5 ) {
      this.message = 'La nueva contraseña debe tener al menos 5 caracteres.';
      return false;
    }

    return true;

  }

}
