import { BoolResult } from './../interfaces/bool-result';
import { ChangeRequest } from './../interfaces/change-request';
import { ValidaResult } from './../interfaces/valida-result';
import { ConfigurationService } from './configuration.service';
import { IntentosResult } from './../interfaces/intentos-result';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  configUrl = 'assets/config.json';

  getConfig() {
    return this.http.get(this.configUrl);
  }

  constructor(
    private http: HttpClient,
    private config: ConfigurationService
    ) { }

  getIntentos(matricula: number, tipoContador: string): Observable<IntentosResult>{
    return this.http.get<IntentosResult>(
      this.config.getBaseUrl() + '/login/intentos/' + matricula.toString() + '/' + tipoContador,
      { headers: this.config.getHeaders() }
    );
  }

  valida(matricula: number, password: string, tipoContador: string): Observable<ValidaResult>{
    if ( tipoContador != null ) {
      return this.http.get<ValidaResult>(
        this.config.getBaseUrl() + '/login/valida/' + matricula.toString() + '/' + password + '/' + tipoContador,
        { headers: this.config.getHeaders() }
      );
    } else {
      return this.http.get<ValidaResult>(
        this.config.getBaseUrl() + '/login/valida/' + matricula.toString() + '/' + password,
        { headers: this.config.getHeaders() }
      );
    }
  }

  cambiar(request: ChangeRequest): Observable<BoolResult>{
    return this.http.post<BoolResult>(
      this.config.getBaseUrl() + '/login/change',
      request,
      { headers: this.config.getHeaders() }
    );
  }


}
