import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { exit } from 'process';
import { ObraSocialService } from 'src/app/services/obra-social.service';
import { OrdersService } from 'src/app/services/orders.service';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';
import { ValidaResult } from '../interfaces/valida-result';
import { InvoicesService } from '../services/invoices.service';
import { PrestadorService } from '../services/prestador.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import * as  printJS from 'print-js';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-seguimiento',
  templateUrl: './seguimiento.component.html',
  styleUrls: ['./seguimiento.component.css']
})
export class SeguimientoComponent implements OnInit {

  plantillas: any[];
  ordenes: any[];
  ordCompartidas: any[];
  numeroOrden: number = null;
  plantilla: any;
  orden: any;
  nombreObraSocial: string;
  imagenes: any[];
  detalle: any[];
  detalleTotal: any[];
  detalleLiquidado: any[];
  fecha: Date = new Date();
  anestesiologo: any;
  isCompartida: boolean = false;
  desde = null;
  hasta = null;
  paciente: string = null;
  filter: number = 4; // orden
  obras_sociales: any[];
  user: ValidaResult;
  selectedOS: any = -1;
  pagos: any[];
  debitos: any[];
  listado: any[];
  @ViewChild('modal', null) modal: NgbModal;
  @ViewChild('modalGrafico', null) modalGrafico: NgbModal;
  fileName: string;

  //beggin grafico
  canvas: any;
  ctx: any;
  @ViewChild('mychart',null) mychart: any;
  //end grafico

  constructor(
    private orders: OrdersService,
    private obraSocialSrv: ObraSocialService,
    private users: UserService,
    private router: Router,
    private toastr: ToastrService,
    private prestSrv: PrestadorService,    
    private osSrv: ObraSocialService,
    private informeSrv: InvoicesService,
    private modalSrv: NgbModal,
    private spinner: NgxSpinnerService,
    
  ) {       
    this.orden = null;   

  }

  ngOnInit(): void {
    //let isLogin = this.users.isLogin();
    this.user = this.users.getUser();
    console.log('usuario: ', this.user);
    this.getObrasSociales();       
  } 

  ngAfterViewInit() {
    this.canvas = this.mychart.nativeElement;
    
  }

  getObrasSociales(){
    this.osSrv.getAllObraSociales(this.users.getUser().matricula).subscribe( result => {
      
      this.obras_sociales = result;

      console.log('obras sociales: ', this.obras_sociales);
    });
  } 

  getEstado(){
    return this.orden.estado == 1 ? 'Sin auditar' : (this.orden.estado == 10 ? '' : 'Auditado');
  }

  getEstadoAsBoolean(orden: any){
    return orden.estado == 1 ? false : true;
  }

  getImgUrl(img: any){
    console.log(img.nombre);
    return "file:///" + img.nombre;    
  } 

  buscarDetalle(event){  
    //let fila = event.parentNode;
    //let fila = index.toString();
    
    //let obtenerOrden = document.getElementsByTagName("td");

    let orden = Number(event.target.innerText);
    console.log('n° orden: ', orden);

    this.numeroOrden = orden;

    console.log('n° orden definitiva: ', this.numeroOrden);

    //innerText
    this.getCabeceraC1();
    this.getDetalle();
    this.getOrdenFechaPago();
    this.getOrdenDebito();    
       
    setTimeout(()=>{
      this.openModal(this.modal); 
    }, 400);    
  }

  openModalGrafico(){
    this.openModal(this.modalGrafico); 
  }

  openModal(content) {
    let options: NgbModalOptions = {
      size: 'xl',
      centered: true,
      backdrop : 'static',
      keyboard : false
    }

    this.modalSrv.open(content, options).result.then((result) => {
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(reason);
    });
  }

  getCabecera(){
    if(isNaN(this.numeroOrden)){
      Swal.fire({
        text: 'Debe ingresar valores numéricos.',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }        
      });
    }

    if(this.filter !=4 && this.filter !=2){      
      if(this.desde != null && this.hasta != null){
        let fecha1 = moment(this.desde);
        let fecha2 = moment(this.hasta);
        let diferencia = fecha2.diff(fecha1, 'months');  
  
        if(diferencia > 3){
          Swal.fire({
            text: 'La Fecha debe ser menor a 3 meses.',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            }        
          });
    
          return
        }
      }else{        
        Swal.fire({
          text: 'Debe seleccionar Fechas.',
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }        
        });  
        return   
      }
    } 

    if(this.filter == 2){      
      if(this.desde != null && this.hasta != null){
        let fecha1 = moment(this.desde);
        let fecha2 = moment(this.hasta);
        let diferencia = fecha2.diff(fecha1, 'months');  
  
        if(diferencia > 24){
          Swal.fire({
            text: 'La Fecha debe ser menor a 2 años.',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            }        
          });
    
          return
        }
      }else{        
        Swal.fire({
          text: 'Debe seleccionar Fechas.',
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }        
        });
  
        return       

      }
      
  
      
    } 

    let filtro: number;
    let usuario: any;
    let orden: any;
    let desde: any;
    let hasta: any;
    let codObraSocial : any;
    let paciente: any;

    //busqueda por orden    
    if(this.filter == 4){  
      filtro = 4,
      usuario =  this.user.matricula;
      orden = Number(this.numeroOrden);
      desde = '2022-12-01';
      hasta = '2022-12-01';
      codObraSocial = 1;
      paciente = 'test';    
    }

    //busqueda por fecha
    if(this.filter == 1){
      filtro = 1,
      usuario =  this.user.matricula;
      orden = 12;
      desde = this.desde;
      hasta = this.hasta;
      codObraSocial = 1;
      paciente = 'test';   
    }

    //busqueda por paciente
    if(this.filter == 2){
      if(this.paciente == null || this.paciente == undefined || this.paciente == ''){
        Swal.fire({
          text: 'Debe ingresar un paciente.',
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }        
        });
        return;
      }
      filtro = 2,
      usuario =  this.user.matricula;
      orden = 12;
      desde = this.desde;
      hasta = this.hasta;
      codObraSocial = 1;
      paciente = this.paciente;        
    }

    //busqueda por o.social
    if(this.filter == 3){
      filtro = 3,
      usuario =  this.user.matricula;
      orden = 12;
      desde = this.desde;
      hasta = this.hasta;
      codObraSocial = this.selectedOS;
      paciente = 'test';        
    }
    this.spinner.show();
    this.informeSrv.seguimientoOrdenCabecera(filtro, usuario, orden, desde, hasta,codObraSocial,paciente).subscribe( result => {
      this.listado = result;
      console.log('listado: ', this.listado);
      this.spinner.hide();
    },error => {
      this.spinner.hide();    

      Swal.fire({
        text: 'Reintentar Búsqueda.',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }        
      });
      
    })
  }

  getCabeceraC1(){
    if(isNaN(this.numeroOrden)){
      Swal.fire({
        text: 'Debe ingresar valores numéricos.',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }        
      });
    }

    

    let request: any = {};

    //busqueda por orden
    /*
    if(this.filter == 4){
      
        let usuario =  this.user.matricula;
        let orden = Number(this.numeroOrden);
        let desde = null;
        let hasta = null;
        let codObraSocial = null;
        let paciente = null;
    
    }
    */
    let filtro = 4;
    let usuario =  this.user.matricula;
    let orden = Number(this.numeroOrden);
    let desde = '2022-12-01';
    let hasta = '2022-12-01';
    let codObraSocial = 1;
    let paciente = 'test';

    if(this.filter == 1){
      request = {
        usuario: this.user.matricula,
        orden: null,
        desde: this.desde,
        hasta: this.hasta,
        codObraSocial: null,
        paciente: null,
      }
    }

    if(this.filter == 2){
      request = {
        usuario: this.user.matricula,
        orden: null,
        desde: this.desde,
        hasta: this.hasta,
        codObraSocial: null,
        paciente: this.paciente,
      }
    }

    if(this.filter == 3){
      request = {
        usuario: this.user.matricula,
        orden: null,
        desde: this.desde,
        hasta: this.hasta,
        codObraSocial: this.selectedOS,
        paciente: null,
      }
    }
    
    this.informeSrv.seguimientoOrdenCabeceraC1(orden).subscribe( result => {
      this.orden = result[0];
      console.log('cabecera: ', this.orden);
    });
  }

  getDetalle(){
    if(isNaN(this.numeroOrden)){
      Swal.fire({
        text: 'Debe ingresar valores numéricos.',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }        
      });
    }

    let nro_orden = Number(this.numeroOrden);
    
    this.informeSrv.seguimientoOrdenDetalle(nro_orden).subscribe( result => {
      this.detalle = result;     
      
      let detalleTotal = this.detalle.reduce((sum, current) => {
        return sum += current.neto_total ? current.neto_total : 0;
      },0);

      let detalleLiquidado = this.detalle.reduce((sum, current) => {
        return sum += current.neto_liquida ? current.neto_liquida : 0;
      },0);

      this.detalleTotal = [{
        detalleTotal: detalleTotal,
        detalleLiquidado: detalleLiquidado,

      }];

      console.log('detalle total',detalleTotal);
      
    });
  }

  getOrdenFechaPago(){
    if(isNaN(this.numeroOrden)){
      Swal.fire({
        text: 'Debe ingresar valores numéricos.',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }        
      });

    }

    let nro_orden = Number(this.numeroOrden);
    
    this.informeSrv.ordenFechaPago(nro_orden).subscribe( result => {
      this.pagos = result;
      console.log(result);
    });
  }

  getOrdenDebito(){
    if(isNaN(this.numeroOrden)){
      Swal.fire({
        text: 'Debe ingresar valores numéricos.',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }        
      });
    }
         
    let nro_orden = Number(this.numeroOrden);    
    
    this.informeSrv.ordenDebito(nro_orden).subscribe( result => {
      this.debitos = result;
      console.log(result);
    });
  }

  
  excelExport(){
    if(!this.listado){
      Swal.fire({
        text: 'No hay datos para exportar al Excel.',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }        
      })
      return
    }
    this.fileName ='seguimiento-orden - ' + this.user + '.xlsx';
    //this.excelSrv.export(this.ordenes); 
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
 
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
 
    /* save to file */  
    XLSX.writeFile(wb, this.fileName);
  }

  print(){   
    
    printJS({
      printable: 'print_seguimiento_orden',
      type: 'html',
      //style: this.printSrv.getTableStyle(),
      scanStyles: false
    })
  }

  

}
