import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { HttpClient } from '@angular/common/http';
import { OrdenPago } from '../interfaces/orden-pago';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(
    private http: HttpClient,
    private config: ConfigurationService
  ) { }

  getOrdersByDate(matricula: number, desde: string, hasta: string, detalle: boolean, tipo: number, valor: string): Observable<OrdenPago[]>{
    var bandera = detalle? 'true': 'false';
    if(tipo===-1){
      return this.http.get<OrdenPago[]>(
        this.config.getBaseUrl() + '/invoices/ordenes/fecha/' + matricula.toString() + '/' + desde + '/' + hasta + '/' + bandera + '/-1/0',
        { headers: this.config.getHeaders() }
      );
    }else{
      if(valor===null){
        valor = '-1'
      }
      return this.http.get<OrdenPago[]>(
        this.config.getBaseUrl() + '/invoices/ordenes/fecha/' + matricula.toString() + '/' + desde + '/' + hasta + '/' + bandera + '/' + tipo.toString() + '/' + valor,
        { headers: this.config.getHeaders() }
      );
    }
  }

  getOrdersByPaciente(matricula: number, paciente: string, detalle: boolean, tipo: number, valor: string): Observable<OrdenPago[]>{
    var bandera = detalle? 'true': 'false';
    
    if(tipo===-1){
      return this.http.get<OrdenPago[]>(
        this.config.getBaseUrl() + '/invoices/ordenes/paciente/' + matricula.toString() + '/' + paciente+ '/' + bandera + '/-1/0',
        { headers: this.config.getHeaders() }
      );
    }else {
      if(valor===null){
        valor = '-1'
      }
      return this.http.get<OrdenPago[]>(
        this.config.getBaseUrl() + '/invoices/ordenes/paciente/' + matricula.toString() + '/' + paciente+ '/' + bandera + '/' + tipo.toString() + '/' + valor,
        { headers: this.config.getHeaders() }
      );
    }
  }

  getOrdersByOS(matricula: number, desde: string, hasta: string, obrasocial: number, detalle: boolean, tipo: number, valor: string): Observable<OrdenPago[]>{
    var bandera = detalle? 'true': 'false';
    if(tipo===-1){
      return this.http.get<OrdenPago[]>(
        this.config.getBaseUrl() + '/invoices/ordenes/os/' + matricula.toString() + '/' + desde + '/' + hasta + '/' + obrasocial.toString()+ '/' + bandera + '/-1/0',
        { headers: this.config.getHeaders() }
      );
    }else {
      if(valor===null){
        valor = '-1'
      }
      return this.http.get<OrdenPago[]>(
        this.config.getBaseUrl() + '/invoices/ordenes/os/' + matricula.toString() + '/' + desde + '/' + hasta + '/' + obrasocial.toString()+ '/' + bandera + '/' + tipo.toString() + '/' + valor,
        { headers: this.config.getHeaders() }
      );
    }
    
  }

  getOrdersByPrestador(matricula: number, desde: string, hasta: string, prestador: number, detalle: boolean, tipo: number, valor: string): Observable<OrdenPago[]>{
    var bandera = detalle? 'true': 'false';
    if(tipo===-1){
      return this.http.get<OrdenPago[]>(
        this.config.getBaseUrl() + '/invoices/ordenes/prestador/' + matricula.toString() + '/' + desde + '/' + hasta + '/' + prestador.toString()+ '/' + bandera + '/-1/0',
        { headers: this.config.getHeaders() }
      );
    }else {
      if(valor===null){
        valor = '-1'
      }
      return this.http.get<OrdenPago[]>(
        this.config.getBaseUrl() + '/invoices/ordenes/prestador/' + matricula.toString() + '/' + desde + '/' + hasta + '/' + prestador.toString()+ '/' + bandera + '/' + tipo.toString() + '/' + valor,
        { headers: this.config.getHeaders() }
      );
    }
    
  }

  getDebitosByOS(matricula: number, desde: string, hasta: string, obrasocial: number, detalle: boolean, tipo: number, valor: string): Observable<any[]>{
    var bandera = detalle? 'true': 'false';
    if(tipo===-1){
      return this.http.get<any[]>(
        this.config.getBaseUrl() + '/invoices/debitos/os/' + matricula.toString() + '/' + desde + '/' + hasta + '/' + obrasocial.toString()+ '/' + bandera + '/-1/0',
        { headers: this.config.getHeaders() }
      );
    }else {
      if(valor===null){
        valor = '-1'
      }
      return this.http.get<any[]>(
        this.config.getBaseUrl() + '/invoices/debitos/os/' + matricula.toString() + '/' + desde + '/' + hasta + '/' + obrasocial.toString()+ '/' + bandera + '/' + tipo.toString() + '/' + valor,
        { headers: this.config.getHeaders() }
      );
    }
    
  }
}
