import { UserService } from './services/user.service';
import { Router } from '@angular/router';
import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'ARA';

  constructor(
    private router: Router,
    private userSrv: UserService
  ){
    router.events.subscribe((val) => {
      this.userSrv.isLogin();
    });
  }

  isNotLogin(): boolean{
    return this.router.url !== '/' &&  this.router.url !== '/change';
  }
}
