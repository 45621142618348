import { Component, OnInit } from '@angular/core';
import { InvoicesService } from '../services/invoices.service';
import { ActivatedRoute } from '@angular/router';
import { Gasto } from '../interfaces/gasto';

@Component({
  selector: 'app-expenses-detail',
  templateUrl: './expenses-detail.component.html',
  styleUrls: ['./expenses-detail.component.css']
})
export class ExpensesDetailComponent implements OnInit {

  tipo: string;
  letra: string;
  cod: string;
  nro: string;
  detalles: Gasto[];

  constructor(
    private invoceSrv: InvoicesService,
    private route: ActivatedRoute
  ) {
    this.tipo = this.route.snapshot.params.tipo;
    this.letra = this.route.snapshot.params.letra;
    this.cod = this.route.snapshot.params.cod;
    this.nro = this.route.snapshot.params.nro;

    this.invoceSrv.getGastos(this.tipo, this.letra, this.cod, this.nro).subscribe( result => {
      this.detalles = result;
    });
  }

  ngOnInit() {
  }

}
