import { Component, OnInit } from '@angular/core';
import { ConfigurationService } from '../services/configuration.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {

  constructor(
    private configSrv: ConfigurationService,
    private userSrv: UserService
  ) { }

  ngOnInit() {
  }

  getUri(){
    return this.configSrv.getBaseUrl() + '/PDF/Plataforma de Facturacion Digital V1.pdf';
  }

  isTipoOQ(): boolean{
    console.log(this.userSrv.getUser().tipo);
    return this.userSrv.getUser().tipo === 'o' || this.userSrv.getUser().tipo === 'q';
  }
}
