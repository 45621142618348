import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { LoginComponent } from './login/login.component';
import { TopMenuComponent } from './top-menu/top-menu.component';
import { ChangeComponent } from './change/change.component';
import { AlertDangerComponent } from './alert-danger/alert-danger.component';
import { VoucherComponent } from './voucher/voucher.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { DateinputComponent } from './dateinput/dateinput.component';
import { NgbDatepickerModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { DateformatService } from './services/dateformat.service';
import { VoucherDetailComponent } from './voucher-detail/voucher-detail.component';
import { AccreditationsComponent } from './accreditations/accreditations.component';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { GrupoPipe } from './pipes/grupo.pipe';
import { ZeroAmountPipe } from './pipes/zero-amount.pipe';
import { TipoRecPipe } from './pipes/tipo-rec.pipe';
import { FiltrarOPPipe } from './pipes/filtrar-op.pipe';
import { ExpensesDetailComponent } from './expenses-detail/expenses-detail.component';
import { HelpComponent } from './help/help.component';
import { OrdersComponent } from './orders/orders.component';
import { IsnullPipe } from './pipes/isnull.pipe';
import { NgxSpinnerModule } from "ngx-spinner";
import { DownloadsComponent } from './downloads/downloads.component';
import { ConveniosComponent } from './convenios/convenios.component';
import { GerenciadoraPipe } from './pipes/gerenciadora.pipe';
import { NormasFacturacionComponent } from './normas-facturacion/normas-facturacion.component';
import { CronogramaComponent } from './cronograma/cronograma.component'; 
import { ToastrModule } from 'ngx-toastr';
import { DebitosComponent } from './debitos/debitos.component';
import { NomencladorComponent } from './nomenclador/nomenclador.component';
import { NomencladorPipe } from './pipes/nomenclador.pipe';
import { SeguimientoComponent } from './seguimiento/seguimiento.component';
import { FacturacionObraSocialComponent } from './graficos/facturacion-obra-social/facturacion-obra-social.component';
import { NovedadesComponent } from './novedades/novedades.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    LoginComponent,
    TopMenuComponent,
    ChangeComponent,
    AlertDangerComponent,
    VoucherComponent,
    DateinputComponent,
    VoucherDetailComponent,
    AccreditationsComponent,
    GrupoPipe,
    ZeroAmountPipe,
    TipoRecPipe,
    FiltrarOPPipe,
    ExpensesDetailComponent,
    HelpComponent,
    OrdersComponent,
    IsnullPipe,
    DownloadsComponent,
    ConveniosComponent,
    GerenciadoraPipe,
    NormasFacturacionComponent,
    CronogramaComponent,
    DebitosComponent,
    NomencladorComponent,
    SeguimientoComponent,
    FacturacionObraSocialComponent,
    NomencladorPipe,
    NovedadesComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([
      { path: '', component: LoginComponent, pathMatch: 'full' },
      { path: 'home', component: HomeComponent },
      { path: 'counter', component: CounterComponent },
      { path: 'fetch-data', component: FetchDataComponent },
      { path: 'change', component: ChangeComponent },
      { path: 'voucher', component: VoucherComponent },
      { path: 'voucher-detail', component: VoucherDetailComponent },
      { path: 'accreditations', component: AccreditationsComponent },
      { path: 'expenses-detail/:tipo/:letra/:cod/:nro', component: ExpensesDetailComponent },
      { path: 'help', component: HelpComponent },
      { path: 'orders', component: OrdersComponent },
      { path: 'downloads', component: DownloadsComponent },
      { path: 'convenios', component: ConveniosComponent },
      { path: 'normas-facturacion', component: NormasFacturacionComponent },
      { path: 'cronograma', component: CronogramaComponent },
      { path: 'debitos', component: DebitosComponent },
      { path: 'nomenclador', component: NomencladorComponent },
      { path: 'seguimiento', component: SeguimientoComponent },
      { path: 'grafico', component: FacturacionObraSocialComponent },
      { path: 'novedades', component: NovedadesComponent },
    ]),
    BrowserAnimationsModule,
    BrowserModule,
    NgbDatepickerModule,
    NgbModule,
    NgxSpinnerModule,
    PdfViewerModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
  ],
  providers: [
    { 
      provide: NgbDateParserFormatter, 
      useFactory: () => { return new DateformatService() } 
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    }
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule {
}
