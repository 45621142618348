import { Router } from '@angular/router';
import { ValidaResult } from './../interfaces/valida-result';
import { UserService } from './../services/user.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.css']
})
export class TopMenuComponent implements OnInit {
  fecha: Date;
  user: ValidaResult;

  constructor(
    private userSrv: UserService,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.user = this.userSrv.getUser();
    console.log(this.user);
    this.fecha = new Date();
  }

}
