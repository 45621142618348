import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NomencladorService } from 'src/app/services/nomenclador.service';
import { UserService } from 'src/app/services/user.service';
import * as  printJS from 'print-js';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-nomenclador',
  templateUrl: './nomenclador.component.html',
  styleUrls: ['./nomenclador.component.css']
})
export class NomencladorComponent implements OnInit {
  resultado: any[];
  searchValue: string = '';
  tipo: string;
  codigo: string;
  incluido: boolean;
  des_tipo: string;
  conNomenclador: any = "0";

  constructor(
    private nomSrv: NomencladorService,
    private userServices: UserService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(){
    this.tipo = "1";
    this.des_tipo = "2"
    
    let isLogin = this.userServices.isLoginTrue();
    if(!isLogin){
      this.router.navigate(['/login'], {  });
    }
    
  }

  excelExport(){
    if(!this.resultado){
      Swal.fire({
        text: 'No hay datos para exportar al Excel.',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }        
      })
      return
    }
    
    let tipo = Number(this.conNomenclador) == 0 ? 'ARA' : 'PAMI';

    let fileName ='nomenclador - ' + tipo + '.xlsx';
    //this.excelSrv.export(this.ordenes); 
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
 
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
 
    /* save to file */  
    XLSX.writeFile(wb, fileName);
  }

  search(){
    this.spinner.show();

    let criterio = {
      //Tipo: Number(this.tipo),
      //Codigo: Number(this.tipo) == 0 ? this.searchValue : null,
      Descripcion: '%'+this.searchValue,
      //Estado: null,
      //DesTipo: Number(this.tipo) == 0 ? null : this.des_tipo,
      //Numero: null,
      conNomenclador: Number(this.conNomenclador) == 0 ? 0 : 1,
    }
    
    this.nomSrv.getNomenclador(criterio).subscribe(result => {            
      this.resultado = result;
      this.spinner.hide();
    }, error => {
      this.toastr.error(error.error.message, 'Nomenclador', { closeButton: true, timeOut: 6000 });
      console.log(error);
      this.spinner.hide();
    })


  }

  clear(){
    this.searchValue = "";
    this.tipo = "0";
    this.des_tipo = "1"
    this.resultado = [];
    this.incluido = false;
    this.conNomenclador = "0";
  }


  
  print(){
    printJS({
      printable: 'printTable',
      type: 'html',
      style: '.modal-body { width: 100%;  } @page{size: auto; margin: 40px;} .codigo2{ font-weight: 900 !important;} .codigo4{font-weight: 800 !important; padding-left: 15px; } .codigo6{ padding-left: 30px;} .negrita2{ font-weight: 900 !important; } .negrita4{ font-weight: 800 !important; }',
      scanStyles: false
    })
  }
  
 

}
