import { UserService } from './user.service';
import { StringResult } from './../interfaces/string-result';
import { Image } from './../interfaces/image';
import { Observable } from 'rxjs';
import { Invoice } from './../interfaces/invoice';
import { ConfigurationService } from './configuration.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InvoiceDetail } from '../interfaces/invoice-detail';
import { Accreditation } from '../interfaces/accreditation';
import { BankAccreditation } from '../interfaces/bank-accreditation';
import { InvoiceDetailItem } from '../interfaces/invoice-detail-item';
import { Gasto } from '../interfaces/gasto';

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {

  constructor(
    private http: HttpClient,
    private config: ConfigurationService,
    private userSrv: UserService
  ) { }

  getInvoices(matricula: number, desde: string, hasta: string, cod_obra: number): Observable<Invoice>{
    return this.http.get<Invoice>(
      this.config.getBaseUrl() + '/invoices/' + matricula.toString() + '/' + desde + '/' + hasta + '/' + cod_obra.toString(),
      { headers: this.config.getHeaders() }
    );
  }

  getInvoiceDetails(tipo: string, letra: string, cod: string, nro: string): Observable<InvoiceDetail[]>{
    return this.http.get<InvoiceDetail[]>(
      this.config.getBaseUrl() + '/invoices/detail/' + tipo + '/' + letra + '/' + cod + '/' + nro,
      { headers: this.config.getHeaders() }
    );
  }

  getInvoiceDetailItems(tipo: string, letra: string, cod: string, nro: string): Observable<InvoiceDetailItem[]>{
    return this.http.get<InvoiceDetailItem[]>(
      this.config.getBaseUrl() + '/invoices/detail/items/' + tipo + '/' + letra + '/' + cod + '/' + nro,
      { headers: this.config.getHeaders() }
    );
  }

  getAccreditations(matricula: number, desde: string, hasta: string,  orden: number): Observable<Accreditation[]>{
    return this.http.get<Accreditation[]>(
      this.config.getBaseUrl() + '/invoices/accreditations/' + matricula.toString() + '/' + desde + '/' + hasta + '/' + orden,
      { headers: this.config.getHeaders() }
    );
  }

  getBankAccreditation(matricula: number, desde: string, hasta: string): Observable<BankAccreditation[]>{
    return this.http.get<BankAccreditation[]>(
      this.config.getBaseUrl() + '/invoices/accreditations/bancos/' + matricula.toString() + '/' + desde + '/' + hasta,
      { headers: this.config.getHeaders() }
  );
  }

  getGastos(tipo: string, letra: string, cod: string, nro: string): Observable<Gasto[]>{
    return this.http.get<Gasto[]>(
      this.config.getBaseUrl() + '/invoices/accreditations/gastos/' + tipo + '/' + letra + '/' + cod + '/' + nro,
      { headers: this.config.getHeaders() }
    );
  }

  getImages(tipo: string, letra: string, cod: string, nro: string): Observable<StringResult> {
    console.log(this.config.getBaseUrl() + '/invoices/images/' + tipo + '/' + letra + '/' + cod + '/' + nro)
    return this.http.get<StringResult>(
      this.config.getBaseUrl() + '/invoices/images/' + tipo + '/' + letra + '/' + cod + '/' + nro,
      { headers: this.config.getHeaders() }
    );
  }

  //inicio
  getImagesInicio(tipo: string, letra: string, cod: string, nro: string, matricula: number): Observable<StringResult> {
    console.log(this.config.getBaseUrl() + '/invoices/imagenes/' + tipo + '/' + letra + '/' + cod + '/' + nro + '/' + matricula.toString())
    return this.http.get<StringResult>(
      this.config.getBaseUrl() + '/invoices/imagenes/' + tipo + '/' + letra + '/' + cod + '/' + nro + '/' + matricula.toString(),
      { headers: this.config.getHeaders() }
    );
  }

  downloadImages(tipo: string, letra: string, cod: string, nro: string): Observable<Response>{
    let uri: string;

    uri = this.config.getBaseUrl();
    uri += '/invoices/images/download/';
    uri += this.userSrv.getUser().matricula.toString();
    uri += '/' + tipo + '/' + letra + '/' + cod + '/' + nro;

    return this.http.get<Response>(
      uri,
      { headers: this.config.getHeaders() }
    );
  }

  getSeguimientoOrdenCabecera(criterio: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.config.getBaseUrl() + '/invoices/seguimientoCabecera',
      criterio,
      { headers: this.config.getHeaders() }
    )
  }

  getSeguimientoOrdenDetalle(criterio: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.config.getBaseUrl() + '/invoices/seguimientoDetalle',
      criterio,
      { headers: this.config.getHeaders() }
    )
  }

  seguimientoOrdenCabecera(filtro: number, usuario: number, nro_orden: number, desde: string, hasta: string, codObraSocial: number, paciente: string): Observable<any>{
    return this.http.get<any>(
      this.config.getBaseUrl() + '/invoices/seguimientoCabecera/' + filtro.toString() + '/' + nro_orden.toString() + '/' + usuario.toString() + '/' + desde + '/' + hasta + '/' + codObraSocial.toString() + '/' + paciente,
      { headers: this.config.getHeaders() }
    );
  }

  seguimientoOrdenDetalle(nro_orden: number): Observable<any>{
    return this.http.get<any>(
      this.config.getBaseUrl() + '/invoices/seguimiento/' + nro_orden.toString(),
      { headers: this.config.getHeaders() }
    );
  }

  getAnestesiologo(matricula: number): Observable<any> {
    return this.http.get<any>(
      this.config.getBaseUrl() + '/invoices/anestesiologos/' + matricula.toString(),
      { headers: this.config.getHeaders() }
    )
  }

  ordenFechaPago(nro_orden: number): Observable<any>{
    return this.http.get<any>(
      this.config.getBaseUrl() + '/invoices/orden/fechaPago/' + nro_orden.toString(),
      { headers: this.config.getHeaders() }
    );
  }

  ordenDebito(nro_orden: number): Observable<any>{
    return this.http.get<any>(
      this.config.getBaseUrl() + '/invoices/orden/debitos/' + nro_orden.toString(),
      { headers: this.config.getHeaders() }
    );
  }
  
  seguimientoOrdenCabeceraC1(nro_orden: number): Observable<any>{
    return this.http.get<any>(
      this.config.getBaseUrl() + '/invoices/seguimientoCabeceraC1/' + nro_orden.toString(),
      { headers: this.config.getHeaders() }
    );
  }

  getGraficoFacturacionByMedico(matricula: number, desde: string, hasta: string): Observable<BankAccreditation[]>{
    return this.http.get<BankAccreditation[]>(
      this.config.getBaseUrl() + '/invoices/grafico/facturacion/obraSocial/' + matricula.toString() + '/' + desde + '/' + hasta,
      { headers: this.config.getHeaders() }
    );
  }

  ordenesNoComputadas(matricula: number, desde: string, hasta: string): Observable<any>{
    return this.http.get<any>(
      this.config.getBaseUrl() + '/invoices/ordenesNoComputadas/' +  matricula.toString() + '/' + desde + '/' + hasta,
      { headers: this.config.getHeaders() }
    );
  }

  getPathPdf(): Observable<any>{
    return this.http.get<any>(
      this.config.getBaseUrl() + '/invoices/getPathPdf/',
      { headers: this.config.getHeaders() }
    );
  }

}
