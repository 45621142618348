import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CargaLotesService {

  constructor(
    private http: HttpClient,
    private config: ConfigurationService,
  ) { }  

  upFile(formData: FormData): Observable<any> {
    return this.http.post<any>(
      this.config.getBaseUrl() + '/carga/carga_lotes',
      formData,
    );
  }

  upFileXml(formData: FormData): Observable<any> {
    return this.http.post<any>(
      this.config.getBaseUrl() + '/carga/carga_lotes_xml',
      formData,
    );
  }

  deleteLote(id: number): Observable<any> {
    return this.http.delete<any>(
      this.config.getBaseUrl() + '/carga/' + id.toString(),      
    );
  }
}
